<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <form-wizard
      color="#7367f0"
      error-color="#ea5455"
      step-size="xs"
      class="w-100 mx-0"
      ref="wizard"
      @on-complete="handleSubmitClick"
    >
      <div slot="title" class="p-0 m-0"></div>
      <tab-content
        title="Basic"
        :selected="true"
        :before-change="() => validateStep('step1')"
      >
        <div class="card mt-3">
          <div
            class="d-flex flex-column align-items-center justify-content-start mx-auto px-2 py-1 rounded border-light"
            style="width: 600px"
          >
            <div class="card-header pt-0 pb-1 px-0 mb-2">
              <div class="w-100 row justify-content-between align-items-center">
                <div
                  class="d-flex flex-column align-items-start justify-content-start col-sm-12"
                >
                  <h4 class="mb-50 font-weight-bold">Basic Info</h4>
                  <p class="mb-0">
                    Complete the following details and click continue to go to
                    the next step or click on the Save & Exit button to create
                    plan with the basic info.
                  </p>
                </div>
              </div>
            </div>
            <validation-observer ref="step1" class="w-100">
              <b-form-group label="Name" class="w-100">
                <validation-provider
                  vid="title"
                  #default="{ errors }"
                  name="Name"
                  :rules="{
                    required: true,
                    min: 3,
                  }"
                >
                  <b-form-input v-model="formData.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label="Description" class="w-100">
                <validation-provider
                  vid="description"
                  #default="{ errors }"
                  name="Description"
                  :rules="{
                    required: true,
                    min: 3,
                  }"
                >
                  <b-form-textarea
                    v-model="formData.description"
                    rows="3"
                    max-rows="4"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="w-100 h-auto rounded border-light px-1 py-50 mb-1">
                <label class="mb-1">Active Duration</label>
                <div
                  class="w-100 d-flex align-items-center justify-content-between"
                >
                  <b-form-group label="From Date" style="width: 45%">
                    <validation-provider
                      #default="{ errors }"
                      name="From Date"
                      :rules="{
                        required: true,
                      }"
                    >
                      <b-form-datepicker
                        @input="updateToDateMinDate"
                        v-model="formData.fromDate"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                        locale="en"
                        :min="minDueDate"
                        :state="errors.length > 0 ? false : null"
                        selected-variant="primary"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <b-form-group label="To Date" style="width: 45%">
                    <validation-provider
                      #default="{ errors }"
                      name="To Date"
                      :rules="{
                        required: true,
                      }"
                    >
                      <b-form-datepicker
                        v-model="formData.toDate"
                        :disabled="formData.fromDate == null"
                        :date-format-options="{
                          year: 'numeric',
                          month: 'short',
                          day: '2-digit',
                          weekday: 'short',
                        }"
                        locale="en"
                        :min="minDueDateForTo"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>

              <div class="w-100 h-auto rounded border-light px-1 py-50 mb-1">
                <label class="mb-1">Pricing</label>
                <template v-for="price in formData.prices">
                  <b-form-group :key="price.name" class="w-100">
                    <label
                      class="mb-0 w-100 d-flex align-items-center justify-content-between"
                      ><legend class="bv-no-focus-ring col-form-label pt-0">
                        {{ `${price.name} Price(USD)` }}
                      </legend>
                      <b-form-checkbox
                        class="mb-25"
                        v-if="price.name != 'Annual'"
                        v-model="price.active"
                        :value="true"
                        plain
                      >
                        Activate
                      </b-form-checkbox>
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      :name="`${price.name} Price(USD)`"
                      :vid="price.name"
                      :rules="{
                        required: price.active,
                      }"
                    >
                      <b-form-input
                        :disabled="price.name != 'Annual' && !price.active"
                        type="number"
                        v-model="price.tariff"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>
              </div>
            </validation-observer>
          </div>
        </div>
      </tab-content>

      <tab-content
        title="Features"
        :before-change="() => validateStep('step2')"
      >
        <div ref="step2" class="h-100 w-100 mt-3">
          <feature-selection-list
            @on-feature-select="handleFeatureSelected"
            @on-feature-remove="handleFeatureRemoveClick"
            :selectedFeatures="selectedFeatures"
          />
        </div>
      </tab-content>

      <tab-content title="Selected Features">
        <div ref="step3" class="h-100 w-100 mt-3">
          <selected-features-list
            :selectedFeatures="selectedFeatures"
            @on-selectedfeature-remove="handleFeatureRemoveClick"
            :updateFeature="handleUpdateFeature"
          />
        </div>
      </tab-content>

      <!-- <tab-content title="Pricing" :before-change="() => validateStep('step4')">
        <price-selection
          :createdPrices="createdPrices"
          @on-price-removed="handlePriceRemoved"
          @on-price-created="handleNewPriceCreated"
        />
      </tab-content> -->

      <template slot="custom-buttons-right" slot-scope="props">
        <b-button
          v-if="props.activeTabIndex == 0"
          @click="handleSaveClick"
          variant="success"
          size="sm"
        >
          <template v-if="isLoading">
            <b-spinner variant="light" small class="mr-50" />
          </template>
          <template v-else>
            <feather-icon icon="SaveIcon" class="mr-50" />
          </template>
          <span class="align-middle">{{
            isLoading ? "Please Wait" : "Save & Exit"
          }}</span></b-button
        >
      </template>

      <b-button
        size="sm"
        variant="primary"
        slot="next"
        slot-scope="props"
        @click="handleNextClick(props.activeTabIndex)"
        ><span class="align-middle">Continue</span
        ><feather-icon icon="ArrowRightIcon" class="ml-50"
      /></b-button>

      <b-button size="sm" variant="primary" slot="prev"
        ><feather-icon icon="ArrowLeftIcon" class="mr-50" /><span
          class="align-middle"
          >Back</span
        ></b-button
      >
      <b-button
        size="sm"
        variant="success"
        slot="finish"
        :disabled="selectedFeatures.length == 0"
        ><feather-icon icon="CheckIcon" class="mr-50" /><span
          class="align-middle"
          >Finish</span
        ></b-button
      >
    </form-wizard>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import FeatureSelectionList from "./FeatureSelectionList.vue";
import SelectedFeaturesList from "./SelectedFeaturesList.vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import PlanMixins from "../../../mixins/PlanMixins";
import PriceSelection from "./PriceSelection.vue";
import PriceFormCard from "./PriceFormCard.vue";

export default {
  mixins: [ResponseMixins, PlanMixins],
  components: {
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
    FormWizard,
    TabContent,
    FeatureSelectionList,
    SelectedFeaturesList,
    BTab,
    BTabs,
    PriceSelection,
    PriceFormCard,
    BFormDatepicker,
  },

  data() {
    return {
      showOverlay: false,
      isLoading: false,
      minDueDate: null,
      minDueDateForTo: null,

      selectedFeatures: [],

      createdPrices: [],

      formData: {
        title: null,
        description: null,
        fromDate: null,
        toDate: null,
        prices: [
          {
            name: "Annual",
            duration: 365,
            tariff: null,
            active: true,
          },
          {
            name: "Monthly",
            duration: 30,
            tariff: null,
            active: false,
          },
        ],
      },

      staticPrices: [
        {
          cardName: "Annual",
          validationRef: "yearly-ref",
          formData: {
            name: null,
            desription: null,
            duration: 365,
            tariff: null,
          },
        },
        {
          cardName: "Monthly",
          validationRef: "monthly-ref",
          formData: {
            name: null,
            desription: null,
            duration: 30,
            tariff: null,
          },
        },
      ],
    };
  },

  watch: {},

  mounted() {
    this.load();
  },

  methods: {
    load() {
      this.setMinDueDate();
    },

    handleUpdateFeature(feature) {
      const featureIndex = this.selectedFeatures.findIndex(
        (f) => f._id == feature._id
      );
      const newSelected = [...this.selectedFeatures];
      newSelected[featureIndex] = feature;
      this.selectedFeatures = newSelected;
    },

    updateToDateMinDate() {
      this.$set(this.formData, "toDate", null);
      const fromDate = new Date(this.formData.fromDate);
      fromDate.setDate(fromDate.getDate() + 1);
      this.minDueDateForTo = fromDate.toISOString().split("T")[0];
    },

    setMinDueDate() {
      let date = new Date();
      date.setDate(date.getDate());
      this.minDueDate = date;
    },

    handleNewPriceCreated(data) {
      this.createdPrices.push(data);
    },

    handlePriceRemoved(index) {
      const newPrices = [...this.createdPrices];
      newPrices.splice(index, 1);
      this.createdPrices = newPrices;
    },

    handleSubmitClick() {
      let planId = null;
      this.showOverlay = true;
      this.createPlan(this.formData)
        .then((mainRes) => {
          console.log(mainRes);
          planId = mainRes.data.data._id;
          if (planId && planId != null) {
            const promiseA = this.addPlanFeatures(
              this.selectedFeatures,
              planId
            );
            // const promiseB = this.addPlanPrices(
            //   this.staticPrices.map((price) => price.formData),
            //   planId
            // );
            Promise.all([promiseA])
              .then(() => {
                this.handleResponse(mainRes);
                this.$router.push("/plans");
              })
              .catch((err) => {
                this.handleError(err);
              })
              .finally(() => {
                this.showOverlay = false;
              });

            // this.addPlanFeatures(this.selectedFeatures, planId)
            //   .then((res) => {
            //     this.handleResponse(res);

            //     this.$router.push("/plans");
            //   })
            //   .finally(() => {
            //     this.showOverlay = false;
            //   });
          }
        })
        .catch((err) => {
          if (err.data) {
            if (err.data.code == 422) {
              let errors = err.data.errors;
              this.$refs.step1.setErrors(errors);
              this.$refs.wizard.navigateToTab(0);
            }
          }
          this.handleError(err);
          this.showOverlay = false;
        });
    },

    handleSaveClick() {
      this.$refs["step1"].validate().then((success) => {
        if (success) {
          this.isLoading = true;
          console.log(this.formData);
          this.createPlan(this.formData)
            .then((mainRes) => {
              this.$router.push("/plans");
              this.handleResponse(mainRes);
            })
            .catch((err) => {
              console.log(err.data);
              if (err.data) {
                if (err.data.code == 422) {
                  let errors = err.data.errors;
                  this.$refs.step1.setErrors(errors);
                }
              }
              this.handleError(err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },

    handleFeatureSelected(feature) {
      // console.log(feature)
      this.selectedFeatures.push(feature);
    },

    handleFeatureRemoveClick(id) {
      let newFeatures = [...this.selectedFeatures];
      newFeatures = newFeatures.filter((feature) => feature._id != id);
      this.selectedFeatures = newFeatures;
      if (newFeatures.length == 0) {
      }
    },

    handleNextClick(index) {
      if (index === 0) {
        // this.addUsecaseAndGotoEdit();
      }
    },

    validateStep(name) {
      if (name == "step2") {
        if (this.selectedFeatures.length > 0) {
          return true;
        }
        this.showToast("Select atleast one feature to continue.", "warning");
        return false;
      }
      // else if (name == "step3") {
      //   if (
      //     this.selectedFeatures.some(
      //       (feature) => feature.quantity == null || feature.quantity == ""
      //     )
      //   ) {
      //     this.showToast(
      //       "Please complete all the fields to continue.",
      //       "warning"
      //     );
      //     return false;
      //   }
      //   return true;
      // }
      else if (name == "step4") {
        if (this.createdPrices.length == 0) {
          this.showToast("Add atleast one price to continue.", "warning");
          return false;
        }
        return true;
      } else {
        var refToValidate = this.$refs[name];
        return refToValidate.validate();
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
// Overrides user variable
@import "~@core/scss/base/components/include";

.vue-form-wizard {
  .wizard-header {
    padding: 0;
  }
  .wizard-navigation {
    .wizard-tab-content {
      margin: 0;
      padding: 0;
    }
    .wizard-icon-circle {
      border: 2px solid $primary;
      .wizard-icon {
        color: $primary;
      }
    }

    .stepTitle {
      color: $body-color;
    }
  }
}

body {
  &.dark-layout {
    .vue-form-wizard {
      .stepTitle {
        color: $theme-dark-body-color;
      }
    }
  }
}
</style>

<style lang="css" scoped>

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
