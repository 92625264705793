<template>
  <sidebar-template title="New Price" :closeAction="closeAction">
    <template #content>
      <validation-observer ref="new_price_form" #default="{ invalid }">
        <b-form @submit.prevent="handleSubmitClick">
          <b-form-group label="Name">
            <validation-provider
              vid="name"
              #default="{ errors }"
              name="Name"
              :rules="{
                required: true,
                min: 3,
              }"
            >
              <b-form-input v-model="formData.name" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description">
            <validation-provider
              vid="description"
              #default="{ errors }"
              name="Description"
              :rules="{
                required: true,
                min: 3,
              }"
            >
              <b-form-textarea
                v-model="formData.description"
                rows="3"
                max-rows="4"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Duration (Days)">
            <validation-provider
              vid="duraion"
              #default="{ errors }"
              name="Duration"
              :rules="{
                required: true,
              }"
            >
              <b-form-input type="number" v-model="formData.duration" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Price(USD)">
            <validation-provider
              vid="tariff"
              #default="{ errors }"
              name="Price(USD)"
              :rules="{
                required: true,
              }"
            >
              <b-form-input type="number" v-model="formData.tariff" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Total Credit">
            <validation-provider
              vid="allowed_total_credit"
              #default="{ errors }"
              name="Total Credit"
              :rules="{
                required: true,
              }"
            >
              <b-form-input
                type="number"
                v-model="formData.allowed_total_credit"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="w-100 d-flex align-items-center justify-content-end">
            <b-button
              :disabled="invalid"
              type="submit"
              variant="success"
              @click="handleNewPriceClick"
              ><feather-icon icon="CheckIcon" class="mr-50" />Submit</b-button
            >
          </div>
        </b-form>
      </validation-observer>
    </template>
  </sidebar-template>
</template>

<script>
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import PlanMixins from "../../../mixins/PlanMixins";

import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  mixins: [ResponseMixins, PlanMixins],

  components: {
    SidebarTemplate,
    BBadge,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
  },

  data() {
    return {
      formData: {
        name: null,
        desription: null,
        duration: null,
        tariff: null,
        allowed_total_credit: null,
      },
    };
  },

  mounted() {},

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
  },

  methods: {
    handleSubmitClick() {
      this.$emit("on-submit", this.formData);
      this.closeAction();
    },
  },
};
</script>

<style lang="scss">
.custom-collapse {
  .card {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5rem;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>