var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sidebar-template',{attrs:{"title":"New Price","closeAction":_vm.closeAction},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('validation-observer',{ref:"new_price_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmitClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"vid":"name","name":"Name","rules":{
              required: true,
              min: 3,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"vid":"description","name":"Description","rules":{
              required: true,
              min: 3,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","max-rows":"4"},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Duration (Days)"}},[_c('validation-provider',{attrs:{"vid":"duraion","name":"Duration","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.duration),callback:function ($$v) {_vm.$set(_vm.formData, "duration", $$v)},expression:"formData.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Price(USD)"}},[_c('validation-provider',{attrs:{"vid":"tariff","name":"Price(USD)","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.tariff),callback:function ($$v) {_vm.$set(_vm.formData, "tariff", $$v)},expression:"formData.tariff"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Total Credit"}},[_c('validation-provider',{attrs:{"vid":"allowed_total_credit","name":"Total Credit","rules":{
              required: true,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number"},model:{value:(_vm.formData.allowed_total_credit),callback:function ($$v) {_vm.$set(_vm.formData, "allowed_total_credit", $$v)},expression:"formData.allowed_total_credit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"disabled":invalid,"type":"submit","variant":"success"},on:{"click":_vm.handleNewPriceClick}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_vm._v("Submit")],1)],1)],1)]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }