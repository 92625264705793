<template>
  <b-form-group :label="`${title} Price(USD)`">
    <validation-provider
      :vid="cardIndex + 'tariff'"
      #default="{ errors }"
      name="Price(USD)"
      :rules="{
        required: true,
      }"
    >
      <b-form-input type="number" v-model="formData.tariff" />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
  <!-- <div class="card mb-0 border-primary rounded" style="width: 42%">
    <div class="card-header d-flex align-items-center justify-content-center">
      <h5 class="mb-0">{{ title }}</h5>
    </div>
    <div
      class="card-body d-flex flex-column align-items-start justify-content-around w-100"
    >
      <b-form class="w-100">
        <b-form-group label="Name">
          <validation-provider
            :vid="cardIndex + 'name'"
            #default="{ errors }"
            name="Name"
            :rules="{
              required: true,
              min: 3,
            }"
          >
            <b-form-input v-model="formData.name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Description">
          <validation-provider
            :vid="cardIndex + 'description'"
            #default="{ errors }"
            name="Description"
            :rules="{
              required: true,
              min: 3,
            }"
          >
            <b-form-textarea
              v-model="formData.description"
              rows="3"
              max-rows="4"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Duration (Days)">
            <validation-provider
              vid="duraion"
              #default="{ errors }"
              name="Duration"
              :rules="{
                required: true,
              }"
            >
              <b-form-input type="number" v-model="formData.duration" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        <b-form-group label="Price(USD)">
          <validation-provider
            :vid="cardIndex + 'tariff'"
            #default="{ errors }"
            name="Price(USD)"
            :rules="{
              required: true,
            }"
          >
            <b-form-input type="number" v-model="formData.tariff" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Total Credit">
          <validation-provider
            :vid="cardIndex + 'allowed_total_credit'"
            #default="{ errors }"
            name="Total Credit"
            :rules="{
              required: true,
            }"
          >
            <b-form-input
              type="number"
              v-model="formData.allowed_total_credit"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </div>
  </div> -->
</template>

<script>
import {
  BOverlay,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BForm,
  BFormTextarea,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BBadge,
    BButton,
    BOverlay,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BFormTextarea,
    BSpinner,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    required,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    formData: {
      type: Object,
      required: true,
    },
    cardIndex: {
      type: Object,
      required: true,
    },
  },

  methods: {
    validateForm() {
      return new Promise((resolve, reject) => {
        this.$refs["PRICE_FORM"]
          .validate()
          .then((success) => {
            if (success) {
              resolve(true);
            } else {
              reject(false);
            }
          })
          .catch(() => {
            reject(false);
          });
      });
    },
  },
};
</script>

<style>
</style>