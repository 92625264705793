<template>
  <div class="w-100 h-auto">
    <b-sidebar
      id="sidebar-new-price"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="420px"
    >
      <new-price-form
        :closeAction="closeNewPriceSidebar"
        @on-submit="handleNewPriceCreated"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header pt-3 pb-1 px-0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-8"
          >
            <h4 class="mb-25">Pricing</h4>
            <p class="mb-0">
              Complete the following details and click finish to add pricing
              info.
            </p>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-4">
            <b-button size="sm" variant="success" @click="handleNewPriceClick"
              ><feather-icon icon="PlusIcon" class="mr-50" />Create New
              Price</b-button
            >
          </div>
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <div class="table-responsive mb-0" style="min-height: 50vh">
          <table role="table" class="table table-hover table-condensed">
            <thead role="rowgroup">
              <tr role="row">
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Duration</th>
                <th scope="col">Price(USD)</th>
                <th scope="col">Total Credit</th>
                <th scope="col" class="text-center">Actions</th>
              </tr>
            </thead>
            <tbody role="rowgroup">
              <template v-if="createdPrices.length > 0">
                <tr
                  role="row"
                  v-for="(price, index) in createdPrices"
                  :key="price.name"
                >
                  <td scope="row">
                    {{ index + 1 }}
                  </td>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      style="max-width: 15vw"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 15vw"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="price.name"
                      >
                        {{ `${price.name}` }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      style="max-width: 20vw"
                    >
                      <p
                        class="mb-0 font-weight-bold text-truncate"
                        style="max-width: 20vw"
                        v-b-tooltip.hover.bottom.v-primary
                        :title="price.description"
                      >
                        {{ `${price.description}` }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      v-if="price.duration && price.duration != null"
                    >
                      <p class="mb-0 font-weight-bold text-truncate">
                        {{ `${price.duration}` }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      v-if="price.tariff && price.tariff != null"
                    >
                      <p class="mb-0 font-weight-bold text-truncate">
                        {{ `${price.tariff}` }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div
                      class="d-flex flex-column align-items-start justify-content-start"
                      v-if="
                        price.allowed_total_credit &&
                        price.allowed_total_credit != null
                      "
                    >
                      <p class="mb-0 font-weight-bold text-truncate">
                        {{ `${price.allowed_total_credit}` }}
                      </p>
                    </div>
                  </td>

                  <td role="cell">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="min-width: 8vw"
                    >
                      <b-button
                        variant="danger"
                        size="sm"
                        @click="handleRemoveClick(index)"
                        ><feather-icon icon="MinusIcon" class="mr-25" /><span
                          >Remove</span
                        ></b-button
                      >
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <empty-table-section title="Prices Empty">
                  <template #content>
                    <p class="font-weight-bold text-center">
                      No prices have been created.
                    </p>
                  </template>
                </empty-table-section>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { BButton, BBadge, BSidebar } from "bootstrap-vue";
import EmptyTableSection from "../../components/EmptyTableSection.vue";
import NewPriceForm from "./NewPriceForm.vue";

export default {
  components: {
    BButton,
    BBadge,
    EmptyTableSection,
    NewPriceForm,
    BSidebar,
  },

  props: {
    createdPrices: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleRemoveClick(index) {
      this.$emit("on-price-removed", index);
    },

    handleNewPriceCreated(price) {
      this.$emit("on-price-created", price);
    },

    handleNewPriceClick() {
      this.openNewPriceSidebar();
    },

    openNewPriceSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-new-price");
    },

    closeNewPriceSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-new-price");
    },
  },
};
</script>

<style>
</style>