<template>
  <div class="w-100 h-auto">
    <b-modal ref="restrict-feature-modal" hide-footer title="Select Standards">
      <div class="d-flex flex-column align-items-start justify-content-start">
        <b-form-checkbox
          @change="handleSelectAllChange"
          v-model="select_all_standards"
        >
          <label class="font-weight-bold">All Standards</label>
        </b-form-checkbox>
        <div
          class="w-100 d-flex flex-column align-items-start justify-content-start mt-50"
          style="max-height: 30vh; overflow: auto"
        >
          <template v-for="standard in loadedStandards">
            <b-form-checkbox
              :disabled="select_all_standards"
              v-model="standard.value"
              @change="
                (e) => {
                  handleStandardChange(e, standard);
                }
              "
              :key="standard[loadedStandardsReferenceKey]"
              plain
            >
              {{ standard.name }}
            </b-form-checkbox>
          </template>
        </div>
        <div
          class="w-100 d-flex align-items-center justify-content-center mt-1"
        >
          <b-button
            variant="success"
            size="md"
            @click="handleStandardSelectionSubmit"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            <span class="align-middle">Submit</span></b-button
          >
        </div>
      </div>
    </b-modal>
    <b-sidebar
      id="sidebar-feature-details-selected-list"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="420px"
    >
      <feature-details-sidebar
        :closeAction="closefeatureDetailsSidebar"
        :featureId="selectedFeatureId"
        :restrictions="selectedStdIds"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header pt-0 pb-75 px-0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-9"
          >
            <span class="d-flex align-items-center justify-content-start">
              <h4 class="mb-25">Selected Features</h4>
              <b-badge pill variant="warning" class="mb-25 ml-25">{{
                selectedFeatures.length
              }}</b-badge>
            </span>
            <p class="mb-0">
              Complete the fields of the selected features and click on Finish
              to continue.
            </p>
          </div>

          <div class="d-flex align-items-start justify-content-end col-sm-3">
            <!-- <b-badge pill variant="warning" class="px-1 shadow-sm"
              ><small class="align-middle">Total Selected:</small
              ><small class="ml-25"
                ><b-badge pill variant="dark">{{
                  selectedFeatures.length
                }}</b-badge></small
              ></b-badge
            > -->

            <!-- <b-badge @click="addMoreClickAction" v-if="addMoreClickAction" pill variant="success" class="px-1 py-50 ml-25 cursor-pointer shadow-sm"
              ><feather-icon icon="PlusIcon" class="mr-25" /><small class="align-middle">Add More</small></b-badge
            > -->
            <b-button
              @click="addMoreClickAction"
              v-if="addMoreClickAction"
              pill
              variant="success"
              size="sm"
              ><feather-icon icon="PlusIcon" class="mr-25" /><span
                class="align-middle"
                >Add More</span
              ></b-button
            >
          </div>
        </div>
      </div>

      <div
        class="w-100 d-flex flex-row align-items-end justify-content-between mb-75"
      >
        <div
          class="app-fixed-search rounded border shadow-sm mb-0"
          style="width: 50%"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div class="table-responsive mb-0" style="min-height: 50vh">
            <table role="table" class="table table-hover table-condensed">
              <thead role="rowgroup">
                <tr role="row">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Source</th>
                  <th scope="col" class="text-center">Quantity</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="selectedFeatures.length > 0">
                  <tr
                    role="row"
                    v-for="(feature, index) in newSelectedFeatures"
                    :key="feature._id"
                    @click="handleDetailViewClick(feature)"
                  >
                    <td scope="row">
                      {{ index + 1 }}
                    </td>

                    <td role="cell" style="max-width: 20vw">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start w-100"
                      >
                        <p class="mb-0 font-weight-bold w-100">
                          {{ `${feature.title}` }}
                        </p>

                        <small
                          v-if="
                            feature.selected_field_values &&
                            feature.selected_field_values.length > 0 &&
                            disableRestrictions === false
                          "
                          ><b-badge variant="warning"
                            >{{
                              feature.selected_field_values.length
                            }}
                            Standard<span
                              v-if="feature.selected_field_values.length > 1"
                              >s</span
                            >&nbsp;Selected</b-badge
                          ></small
                        >

                        <!-- <small
                          v-else-if="
                            feature.meta.data_url &&
                            (!feature.selected_field_values ||
                              feature.selected_field_values.length == 0) &&
                            disableRestrictions === false
                          "
                          ><b-badge variant="warning"
                            >0 New Restrictions</b-badge
                          ></small
                        > -->

                        <!-- <small
                          v-else-if="
                            feature.meta &&
                            feature.meta.selected_field_values &&
                            feature.meta.selected_field_values.length > 0 &&
                            disableRestrictions === false
                          "
                          ><b-badge variant="warning"
                            >{{
                              feature.meta.selected_field_values.length
                            }}
                            Restriction<span
                              v-if="
                                feature.meta.selected_field_values.length > 1
                              "
                              >s</span
                            ></b-badge
                          ></small
                        > -->
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                      >
                        <small
                          ><b-badge
                            v-if="feature.module && feature.module != null"
                            variant="primary"
                            >{{ feature.module }}</b-badge
                          ></small
                        >
                      </div>
                    </td>

                    <td role="cell" style="min-width: 5vw; max-width: 120px">
                      <div
                        class="d-flex align-items-center justify-content-center w-100"
                        v-if="feature.unit != 'boolean'"
                      >
                        <b-form-group>
                          <label v-if="feature.unit == 'count'"
                            ><small>Count</small></label
                          >
                          <label v-if="feature.unit == 'credit'"
                            ><small>Credits Per Unit</small></label
                          >
                          <b-form-input
                            @click.stop
                            v-model="feature.inputValue"
                            type="number"
                          />
                        </b-form-group>
                      </div>

                      <div
                        class="d-flex flex-column align-items-center justify-content-center w-100"
                        v-else
                      >
                        <label><small>Boolean</small></label>
                        <b-form-checkbox
                          class="custom-control-success"
                          :disabled="true"
                          checked="true"
                          inline
                        >
                          Yes
                        </b-form-checkbox>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="min-width: 8vw"
                      >
                        <b-button
                          :disabled="
                            feature.is_primary && feature.is_primary == 1
                          "
                          variant="danger"
                          size="sm"
                          @click.stop="handleRemoveClick(feature._id)"
                          ><feather-icon icon="MinusIcon" class="mr-25" /><span
                            >Remove</span
                          ></b-button
                        >
                        <b-button
                          v-if="
                            feature.meta &&
                            feature.meta.data_url &&
                            disableRestrictions === false
                          "
                          variant="primary"
                          size="sm"
                          class="ml-50"
                          @click.stop="handleRestrictClick(feature)"
                        >
                          <span class="text-center align-middle">
                            <feather-icon icon="PlusIcon" class="mr-25" />
                            <span>Choose Standards</span>
                          </span>
                        </b-button>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Selected Features Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        No features have been selected.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
  BFormCheckbox,
  BFormGroup,
  BModal,
  VBPopover,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import FeatureMixins from "@/mixins/FeatureMixins";

import EmptyTableSection from "../../components/EmptyTableSection.vue";
import FeatureDetailsSidebar from "@/views/features/components/FeatureDetailsSidebar.vue";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";

export default {
  mixins: [ResponseMixins, FeatureMixins],
  directives: {
    "b-tooltip": VBTooltip,
    "b-popover": VBPopover,
  },

  components: {
    BCardCode,
    BTable,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BSidebar,
    BOverlay,
    EmptyTableSection,
    FeatureDetailsSidebar,
    BFormCheckbox,
    FeatherIcon,
    BFormGroup,
    BModal,
  },
  props: {
    selectedFeatures: {
      type: Array,
      required: true,
    },

    addMoreClickAction: {
      type: Function,
      required: false,
    },

    updateFeature: {
      type: Function,
      required: false,
      default: () => {},
    },

    disableRestrictions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedFeatureId: null,

      showOverlay: false,

      loadedStandards: [],
      loadedStandardsReferenceKey: null,
      currentFeatureSelectedStandards: [],

      selectedStdIds: [],
      select_all_standards: false,

      filters: {
        search: null,
        module: null,
      },
    };
  },

  watch: {
    filters: {
      handler: function (newValues) {},
      deep: true,
    },
  },
  computed: {
    newSelectedFeatures() {
      if (this.filters.search == null || this.filters.search == "") {
        return this.selectedFeatures;
      } else {
        return this.selectedFeatures.filter((feature) =>
          feature.title
            .toLowerCase()
            .includes(this.filters.search.toLowerCase())
        );
      }
    },
    moduleOptions() {
      return [
        { text: "--Select--", value: null },
        { text: "Threat Management", value: "Threat Management" },
        { text: "GRC", value: "GRC" },
        {
          text: "Third Party Risk Management",
          value: "Third Party Risk Management",
        },
        { text: "Admin Portal", value: "Admin Portal" },
      ];
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      // console.log(this.selectedFeatures);
    },

    handleSelectAllChange(value) {
      if (value === true) {
        this.loadedStandards = this.loadedStandards.map((std) => {
          std.value = true;
          return std;
        });
        this.currentFeatureSelectedStandards = this.loadedStandards;
      }
    },

    handleStandardSelectionSubmit() {
      this.hideRestrictModal();
      const newFeature = this.selectedFeatures.find(
        (f) => f._id == this.selectedFeatureId
      );
      newFeature["selected_field_values"] =
        this.currentFeatureSelectedStandards.map((std) => std._id);
      this.updateFeature(newFeature);
      this.resetRestrictions();
      console.log("Selected Features", this.selectedFeatures);
    },

    handleStandardChange(value, std) {
      //Add Standard
      if (value === true) {
        const newSelected = [...this.currentFeatureSelectedStandards];
        newSelected.push(std);
        this.currentFeatureSelectedStandards = newSelected;
        console.log(
          "Selected Standards Added",
          this.currentFeatureSelectedStandards
        );
      }
      //Remove Standard
      else if (value === false) {
        const newSelected = this.currentFeatureSelectedStandards.filter(
          (f) => f._id != std._id
        );
        this.currentFeatureSelectedStandards = newSelected;
        console.log(
          "Selected Standards Removed",
          this.currentFeatureSelectedStandards
        );
      }
    },

    handleRestrictClick(feature) {
      console.log(feature);
      this.showOverlay = true;
      const data_url = feature.meta.data_url;
      this.loadedStandardsReferenceKey = feature.meta.reference_field;
      this.selectedFeatureId = feature._id;
      this.getStandardsFromURL(data_url)
        .then((standards) => {
          this.resetRestrictions();

          // Feature contains Already selected restrictions
          if (
            feature.selected_field_values &&
            feature.selected_field_values.length > 0
          ) {
            this.loadedStandards = standards.map((std) => {
              return {
                ...std,
                value: feature.selected_field_values.includes(std._id)
                  ? true
                  : false,
              };
            });
            this.currentFeatureSelectedStandards = this.loadedStandards.filter(
              (std) => std.value === true
            );
          }
          // Feature contains no restrictions
          else {
            this.loadedStandards = standards.map((std) => {
              return {
                ...std,
                value: false,
              };
            });
          }

          this.showRestrictModal();

          // if (
          //   feature.selected_field_values &&
          //   feature.selected_field_values.length > 0
          // ) {
          //   this.currentFeatureSelectedStandards = standards.map((std) => {
          //     if (feature.selected_field_values.includes(std._id)) {
          //       return {
          //         ...std,
          //         value: true,
          //       };
          //     }
          //   });
          // } else if (
          //   feature.meta.selected_field_values &&
          //   feature.meta.selected_field_values.length > 0
          // ) {
          //   this.currentFeatureSelectedStandards = standards.map((std) => {
          //     if (feature.meta.selected_field_values.includes(std._id)) {
          //       return {
          //         ...std,
          //         value: true,
          //       };
          //     }
          //   });
          // }
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    showRestrictModal() {
      this.$refs["restrict-feature-modal"].show();
    },
    hideRestrictModal() {
      this.$refs["restrict-feature-modal"].hide();
    },

    resetRestrictions() {
      this.loadedStandards = [];
      this.loadedStandardsReferenceKey = null;
      this.currentFeatureSelectedStandards = [];
    },

    getStandardsFromURL(url) {
      return new Promise((resolve, reject) => {
        const options = {
          method: "GET",
          url: url,
        };
        this.$http(options)
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    handleRemoveClick(id) {
      this.$emit("on-selectedfeature-remove", id);
    },

    handleDetailViewClick(feature) {
      this.selectedFeatureId = feature._id;
      if (
        feature.selected_field_values &&
        feature.selected_field_values != null &&
        feature.selected_field_values.length > 0
      ) {
        this.selectedStdIds = feature.selected_field_values;
      }
      this.openfeatureDetailsSidebar();
    },

    openfeatureDetailsSidebar: function () {
      this.$root.$emit(
        "bv::toggle::collapse",
        "sidebar-feature-details-selected-list"
      );
    },

    closefeatureDetailsSidebar: function () {
      this.$root.$emit(
        "bv::toggle::collapse",
        "sidebar-feature-details-selected-list"
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>

<style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.65rem 0.75rem;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>