<template>
  <div class="w-100 h-auto">
    <b-sidebar
      id="sidebar-feature-details-selection-list"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="420px"
    >
      <feature-details-sidebar
        :closeAction="closefeatureDetailsSidebar"
        :featureId="selectedFeatureId"
      />
    </b-sidebar>
    <div class="card">
      <div class="card-header pt-0 pb-75 px-0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-sm-9"
          >
            <h4 class="mb-25">{{ title }}</h4>
            <p class="mb-0">
              {{ description }}
            </p>
          </div>

          <div class="d-flex align-items-center justify-content-end col-sm-3">
            <b-badge
              v-if="goToSelectedAction"
              @click="goToSelectedAction"
              pill
              variant="warning"
              class="px-1 shadow-sm cursor-pointer"
              ><small class="align-middle">Total Selected:</small
              ><small class="ml-25"
                ><b-badge pill variant="dark">{{
                  selectedFeatures.length
                }}</b-badge></small
              ></b-badge
            >
            <b-badge v-else pill variant="warning" class="px-1 shadow-sm"
              ><small class="align-middle">Total Selected:</small
              ><small class="ml-25"
                ><b-badge pill variant="dark">{{
                  selectedFeatures.length
                }}</b-badge></small
              ></b-badge
            >
          </div>
        </div>
      </div>
      <div
        class="w-100 d-flex flex-row align-items-end justify-content-between mb-75"
      >
        <div
          class="app-fixed-search rounded border shadow-sm mb-0"
          style="width: 50%"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>

        <div style="width: 20%">
          <b-form-group class="mb-0">
            <label><small>Filter by Module</small></label>
            <b-form-select v-model="filters.module" :options="moduleOptions" />
          </b-form-group>
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div class="table-responsive mb-0" style="min-height: 50vh">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <!-- <th scope="col">#</th> -->
                  <th scope="col">Name</th>
                  <th scope="col">Module</th>
                  <!-- <th scope="col">Tariff</th> -->
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="Object.keys(features).length > 0">
                  <tr
                    role="row"
                    v-for="(feature, key) in features"
                    :key="key"
                    @click="handleDetailViewClick(feature._id)"
                  >
                    <!-- <td scope="row" style="width: 120px">
                      {{ index + pagination.from }}
                    </td> -->

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start w-100"
                      >
                        <p class="mb-0 font-weight-bold">
                          {{ `${feature.title}` }}
                        </p>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                      >
                        <b-badge
                          v-if="feature.module && feature.module != null"
                          variant="primary"
                          >{{ feature.module }}</b-badge
                        >
                      </div>
                    </td>

                    <!-- <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                        v-if="feature.tariff && feature.tariff != null"
                      >
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          v-b-tooltip.hover.bottom.v-primary
                          :title="feature.tariff"
                        >
                          {{ `${feature.tariff}` }}
                        </p>
                      </div>
                    </td> -->

                    <td role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        style="min-width: 8vw"
                      >
                        <b-button
                          style="min-width: 140px"
                          :disabled="
                            feature.is_primary && feature.is_primary == 1
                          "
                          v-if="
                            !selectedFeatures.some((f) => f._id == feature._id)
                          "
                          variant="success"
                          size="md"
                          @click.stop="handleSelectClick(feature)"
                          ><feather-icon icon="PlusIcon" class="mr-25" /><span
                            >Select</span
                          ></b-button
                        >
                        <b-button
                          style="min-width: 140px"
                          v-else
                          :disabled="
                            feature.is_primary && feature.is_primary == 1
                          "
                          variant="danger"
                          size="md"
                          @click.stop="handleRemoveClick(key)"
                          ><feather-icon icon="MinusIcon" class="mr-25" /><span
                            >Remove</span
                          ></b-button
                        >
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Features Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        No features have been found.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
      <div class="card-body my-0 py-0">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";
import FeatureMixins from "@/mixins/FeatureMixins";

import EmptyTableSection from "../../components/EmptyTableSection.vue";
import FeatureDetailsSidebar from "@/views/features/components/FeatureDetailsSidebar.vue";

export default {
  mixins: [ResponseMixins, FeatureMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BSidebar,
    BOverlay,
    EmptyTableSection,
    FeatureDetailsSidebar,
    BFormGroup,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "Select Features",
    },
    description: {
      type: String,
      required: false,
      default:
        "Select your desired features and click continue to go to the next step.",
    },
    selectedFeatures: {
      type: Array,
      required: true,
    },
    goToSelectedAction: {
      type: Function,
      required: false,
    },
  },
  computed: {
    moduleOptions() {
      return [
        { text: "--Select--", value: null },
        { text: "Admin Portal", value: "Admin Portal" },
        { text: "GRC", value: "GRC" },
        { text: "Secusy App", value: "Secusy App" },
        { text: "Threat Management", value: "Threat Management" },
        {
          text: "Third Party Risk Management",
          value: "Third Party Risk Management",
        },
      ];
    },
  },
  data() {
    return {
      selectedFeatureId: null,
      pagination_pos: "center",
      showOverlay: false,
      pagination: {
        perPage: 10,
        total: 1,
        from: 1,
      },

      filters: {
        search: null,
        module: null,
        page: 1,
        include_primary: 1,
      },

      features: {},
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getFeaturesAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.getFeaturesAndSetData(this.filters);
    },

    handleRemoveClick(id) {
      this.$emit("on-feature-remove", id);
    },

    handleSelectClick(feature) {
      if (this.selectedFeatures && this.selectedFeatures.length == 0) {
        const newFeature = {
          ...feature,
          additional_qty_tariff: null,
        };
        if (feature.unit == "boolean") {
          newFeature.quantity = 1;
        } else {
          newFeature.quantity = null;
        }
        this.$emit("on-feature-select", newFeature);
      } else {
        if (!this.selectedFeatures.some((f) => f._id == feature._id)) {
          console.log(feature._id);
          const newFeature = {
            ...feature,
            additional_qty_tariff: null,
            inputValue: null,
          };
          if (feature.unit == "boolean") {
            newFeature.quantity = 1;
          } else {
            newFeature.quantity = null;
          }
          this.$emit("on-feature-select", newFeature);
        }
      }
    },

    handleDetailViewClick(id) {
      this.selectedFeatureId = id;
      this.openfeatureDetailsSidebar();
    },

    openfeatureDetailsSidebar: function () {
      this.$root.$emit(
        "bv::toggle::collapse",
        "sidebar-feature-details-selection-list"
      );
    },

    closefeatureDetailsSidebar: function () {
      this.$root.$emit(
        "bv::toggle::collapse",
        "sidebar-feature-details-selection-list"
      );
    },

    getFeaturesAndSetData(params) {
      this.getFeatures(params)
        .then((res) => {
          console.log(res);
          const newFeatures = {};
          res.data.data.data.forEach((feature, i) => {
            newFeatures[feature._id] = feature;
            if (feature.is_primary && feature.is_primary == 1) {
              console.log("Feature Index", i);
              this.handleSelectClick(feature);
            }
          });
          this.features = newFeatures;
          // this.$set(this.filters, "page", res.data.data.current_page);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>

<!-- <style lang="scss" scoped>
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.65rem;
}
</style> -->
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>