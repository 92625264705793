<template>
  <div class="card">
    <div class="card-header pb-75" style="padding-right: 0">
      <div class="w-100 row justify-content-between align-items-center">
        <div
          class="d-flex flex-column align-items-center justify-content-start col-sm-12"
        >
          <h3 class="mb-25 font-weight-bolder">Create New Plan</h3>
          <p class="mb-50">
            Complete the following steps to create a plan.
          </p>
        </div>
      </div>
    </div>
    <div class="card-body">
      <plan-form />
    </div>
  </div>
</template>

<script>
import PlanForm from "./components/PlanForm.vue";
export default {
  components: { PlanForm },
};
</script>

<style>
</style>